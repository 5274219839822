if (0 < document.querySelectorAll('.services-slider').length) {
  var featuredPostsCarousel = new Swiper('.services-slider__carousel', {
    // Optional parameters
    direction: 'horizontal',
    slidesPerView: 1,
    // Pagination
    pagination: {
      el: '.services-slider__carousel__pagination.swiper-pagination',
      clickable: true
    },
    // Navigation arrows
    navigation: {
      nextEl: '.services-slider__carousel__navigation .swiper-button-next',
      prevEl: '.services-slider__carousel__navigation .swiper-button-prev'
    },
    breakpoints: {
      400: {
        slidesPerView: 2,
        spaceBetween: 50
      },
      600: {
        slidesPerView: 3,
        spaceBetween: 50
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 50
      }
    }
  });
}